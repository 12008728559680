<template>
  <div id="ib-report">
    <div class="wrapper-main">
      <div class="wrapper-content">
        <AccountNumber @accountCallback="queryIbReportData"></AccountNumber>

        <!-- 中间部分 -->
        <!-- 提交部分 -->
        <div class="calendar_content clearfix" v-if="updateTimeVisible">
          <DateRangePicker v-bind:startDate.sync="startDate" v-bind:endDate.sync="endDate"></DateRangePicker>
          <div class="right_box">
            <loading-button class="btn-red" :callback="queryIbReportData" text="common.keys.UPDATE"></loading-button>
          </div>
        </div>
        <div class="main">
          <!-- 5个盒子 -->
          <div class="main-top">
            <div class="calendar-box">
              <ul>
                <li
                  v-on:click="selectedCard = 'netFunding'"
                  class="card"
                  :class="{ active: selectedCard == 'netFunding' }"
                  data-testid="netFunding"
                >
                  <div class="top">
                    <span>{{ $t('ibReport.netFunding') }}</span>
                  </div>
                  <div class="bottom">
                    <p v-if="accountDataPermission">
                      <span>{{ currAccountCurrencyFilter }}</span>
                      {{ cardData.netFunding.value | currency('') }}
                    </p>
                    <div v-else>***</div>
                  </div>
                </li>

                <li
                  v-on:click="selectedCard = 'deposits'"
                  class="card"
                  :class="{ active: selectedCard == 'deposits' }"
                  data-testid="deposits"
                >
                  <div class="top">
                    <span>{{ $t('ibReport.deposits') }}</span>
                    <!-- <img src="@/assets/images/up.png" alt /> -->
                  </div>
                  <div class="bottom">
                    <p v-if="accountDataPermission">
                      <span>{{ currAccountCurrencyFilter }}</span>
                      {{ cardData.deposits.value | currency('') }}
                    </p>
                    <div v-else>***</div>
                  </div>
                </li>
                <li
                  v-on:click="selectedCard = 'withdraw'"
                  class="card"
                  :class="{ active: selectedCard == 'withdraw' }"
                  data-testid="withdraw"
                >
                  <div class="top">
                    <span>{{ $t('ibReport.withdraw') }}</span>
                    <!-- <img src="@/assets/images/down.png" alt /> -->
                  </div>
                  <div class="bottom">
                    <p v-if="accountDataPermission">
                      <span>{{ currAccountCurrencyFilter }}</span>
                      {{ cardData.withdraw.value | currency('') }}
                    </p>
                    <div v-else>***</div>
                  </div>
                </li>
                <li
                  v-on:click="selectedCard = 'openedAccounts'"
                  class="card"
                  :class="{ active: selectedCard == 'openedAccounts' }"
                  data-testid="openedAccounts"
                >
                  <div class="top">
                    <span>{{ $t('ibReport.openedAccs') }}</span>
                  </div>
                  <div class="bottom">
                    <p v-if="accountDataPermission">{{ cardData.openedAccounts.value | currency('', 0) }}</p>
                    <div v-else>***</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 表格部分 -->
          <div class="main-bottom">
            <div class="content clearfix">
              <!-- 1. net Funding 
            2.deposits 
            3.withdraw -->
              <div
                class="content-box conHeader"
                v-show="['netFunding', 'deposits', 'withdraw'].indexOf(selectedCard) >= 0"
              >
                <span>
                  {{ $t('ibReport.funding') }} {{ $t('common.keys.FROM') }}
                  <span class="blue">
                    {{ startDate | formatDate('DD/MM/YYYY') }} - {{ endDate | formatDate('DD/MM/YYYY') }} >>></span
                  >
                </span>
                <download-button
                  :noPermission="!accountDataPermission"
                  class="button"
                  :callback="downLoad"
                  icon="el-icon-download"
                >
                </download-button>
              </div>

              <!-- deposit -->
              <div class="content-box" v-show="['netFunding', 'deposits'].indexOf(selectedCard) >= 0">
                <span>
                  {{ $t('ibReport.deposits') }}
                </span>

                <div class="table-box">
                  <el-table
                    :data="depositDisplayData"
                    style="width: 100%"
                    :default-sort="{ prop: timeTypeDefault, order: 'descending' }"
                    @sort-change="sortChange"
                    ref="depositTable"
                  >
                    <el-table-column min-width="120">
                      <template slot="header" slot-scope="scope">
                        <el-select v-model="timeType" data-testid="timeType" @change="handleTimeTypeChange">
                          <el-option
                            v-for="item in timeTypeList"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label"
                            :data-testid="item.value"
                          ></el-option>
                        </el-select>
                      </template>
                      <template slot-scope="scope">
                        <!-- Submission Time:createTime, Processed Time:updateTime -->
                        <span>{{ scope.row[timeTypeDefault] | formatDate('DD/MM/YYYY') }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('home.Account')" min-width="120">
                      <el-table-column>
                        <template slot="header" slot-scope="scope">
                          <el-input
                            v-model="depositAccountNumber"
                            auto-complete="off"
                            type="text"
                            v-on:input="searchDepositData"
                          />
                        </template>
                        <template slot-scope="scope">
                          <span>{{ scope.row.mt4Account }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column :label="$t('home.Name')" min-width="120">
                      <el-table-column>
                        <template slot="header" slot-scope="scope">
                          <el-input
                            v-model="depositAccountName"
                            auto-complete="off"
                            type="text"
                            v-on:input="searchDepositData"
                          />
                        </template>
                        <template slot-scope="scope">
                          <span>{{ scope.row.realName }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.method')" min-width="120">
                      <template slot-scope="scope">
                        <span>{{ showDepositMethod(scope.row.paymentType, scope.row.paymentChannel) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.currency')" min-width="120">
                      <template slot-scope="scope">
                        <span>{{ scope.row.currency }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="amount"
                      :label="$t('ibReport.depositAmount')"
                      min-width="120"
                      sortable="custom"
                      sort-by="amount"
                      :sort-orders="['ascending', 'descending']"
                      class-name="depositTable"
                    >
                      <template slot-scope="scope">
                        <span>{{ scope.row.amount | currency('') }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 分页 -->
                <pagination
                  v-bind:table-data="depositTableData"
                  v-bind:display-data.sync="depositDisplayData"
                ></pagination>
              </div>

              <!-- withdraw -->
              <div class="content-box" v-show="['netFunding', 'withdraw'].indexOf(selectedCard) >= 0">
                <span>
                  {{ $t('ibReport.withdrawals') }}
                </span>

                <div class="table-box">
                  <el-table
                    :data="withdrawDisplayData"
                    style="width: 100%"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    @sort-change="sortChange"
                    ref="withdrawTable"
                  >
                    <el-table-column min-width="120">
                      <template slot="header" slot-scope="scope">
                        <el-select v-model="timeType" data-testid="timeType" @change="handleTimeTypeChange">
                          <el-option
                            v-for="item in timeTypeList"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label"
                            :data-testid="item.value"
                          ></el-option>
                        </el-select>
                      </template>
                      <template slot-scope="scope">
                        <!-- Submission Time:createTime, Processed Time:updateTime -->
                        <span>{{ scope.row[timeTypeDefault] | formatDate('DD/MM/YYYY') }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('home.Account')" min-width="120">
                      <el-table-column>
                        <template slot="header" slot-scope="scope">
                          <el-input
                            v-model="withdrawAccountNumber"
                            auto-complete="off"
                            type="text"
                            v-on:input="searchWithdrawData"
                          />
                        </template>
                        <template slot-scope="scope">
                          <span>{{ scope.row.mt4Account }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column :label="$t('home.Name')" min-width="120">
                      <el-table-column>
                        <template slot="header" slot-scope="scope">
                          <el-input
                            v-model="withdrawAccountName"
                            auto-complete="off"
                            type="text"
                            v-on:input="searchWithdrawData"
                          />
                        </template>
                        <template slot-scope="scope">
                          <span>{{ scope.row.realName }}</span>
                        </template>
                      </el-table-column>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.method')" min-width="120">
                      <template slot-scope="scope">
                        <span>{{ showWithdrawMethod(scope.row.withdrawType, scope.row.transferCountry) }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.currency')" min-width="120">
                      <template slot-scope="scope">
                        <span>{{ scope.row.currency }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="amount"
                      :label="$t('ibReport.withdrawalAmount')"
                      min-width="120"
                      sortable="custom"
                      sort-by="amount"
                      :sort-orders="['ascending', 'descending']"
                      class-name="withdrawTable"
                    >
                      <template slot-scope="scope">
                        <span>{{ scope.row.amount | currency('') }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <!-- 分页 -->
                <pagination
                  v-bind:table-data="withdrawTableData"
                  v-bind:display-data.sync="withdrawDisplayData"
                ></pagination>
              </div>

              <!-- 1. opened Accounts -->
              <div class="content-box" v-if="selectedCard == 'openedAccounts'">
                <div class="conHeader">
                  <span>
                    {{ $t('ibReport.openedAccs') }} {{ $t('common.keys.FROM') }}
                    <span class="blue">
                      {{ startDate | formatDate('DD/MM/YYYY') }} - {{ endDate | formatDate('DD/MM/YYYY') }} >>></span
                    >
                  </span>
                  <download-button
                    class="button"
                    :callback="downLoad"
                    icon="el-icon-download"
                    :noPermission="!accountDataPermission"
                  >
                  </download-button>
                </div>

                <div class="table-box">
                  <el-table
                    :data="openedAccountsDisplayData"
                    style="width: 100%; margin-top: 20px;"
                    @sort-change="sortChange"
                  >
                    <el-table-column :label="$t('common.keys.DATE')" min-width="120">
                      <template slot-scope="scope">
                        <span>{{ scope.row.approvedDate | date('DD/MM/YYYY') }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.ACCNUM')" min-width="140">
                      <template slot-scope="scope">
                        <span>{{ scope.row.mt4Account }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.NAME')" min-width="120">
                      <template slot-scope="scope">
                        <span>{{ scope.row.name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.keys.EMAIL')" min-width="130">
                      <template slot-scope="scope">
                        <span>{{ scope.row.email }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="type"
                      :label="$t('common.keys.ACCTYPE')"
                      sortable
                      sort-by="type"
                      min-width="170"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <template slot-scope="scope">
                        <span> {{ $config.accountTypeMaps[scope.row.type] }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="platform"
                      :label="$t('common.keys.PLATFORM')"
                      sortable
                      sort-by="platform"
                      :min-width="130"
                      :sort-orders="['ascending', 'descending']"
                    ></el-table-column>
                    <el-table-column
                      prop="currency"
                      :label="$t('common.keys.BASECURRENCY')"
                      sortable
                      sort-by="currency"
                      :min-width="170"
                      :sort-orders="['ascending', 'descending']"
                    ></el-table-column>
                    <el-table-column
                      prop="balance"
                      :label="$t('common.keys.BALANCE')"
                      sortable
                      sort-by="balance"
                      :min-width="150"
                      :sort-orders="['ascending', 'descending']"
                    >
                      <template slot-scope="scope">
                        <span>{{ scope.row.currency | currencySymbol }}{{ scope.row.balance | currency('') }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <pagination
                  v-bind:table-data="openedAccountsTableData"
                  v-bind:display-data.sync="openedAccountsDisplayData"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <downloadDialog
      v-if="downloadVisible"
      :downloadVisible.sync="downloadVisible"
      @closeDownloadDoalog="closeDownloadDoalog"
      @onConfrim="onConfrim"
    ></downloadDialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import DateRangePicker from '@/components/DateRangePicker';
import LoadingButton from '@/components/LoadingButton';
import AccountNumber from '@/components/form/AccountNumber';
import { apiQueryIbReportData } from '@/resource';
import DownloadButton from '@/components/download/DownloadButton';
import { downLoadExcelMode } from '@/util/downLoadExcelMode';
import downloadDialog from '@/components/download/Dialog';
import depositHistory from '@/constants/payment/depositHistory';
import withdrawHistory from '@/constants/payment/withdrawHistory';

export default {
  components: { Pagination, DateRangePicker, LoadingButton, AccountNumber, DownloadButton, downloadDialog },
  data() {
    return {
      downloadVisible: false,
      updateTimeVisible: true,
      userID: this.$store.state.common.CUID,
      startDate: '',
      endDate: '',
      cardData: {
        netFunding: { label: 'NET FUNDING', value: 0 },
        deposits: { label: 'DEPOSITS', value: 0 },
        withdraw: { label: 'WITHDRAW', value: 0 },
        openedAccounts: { label: 'OPENED ACCOUNTS', value: 0 }
      },
      selectedCard: '',
      fundingTableData: [],
      fundingDisplayData: [],
      openedAccountsTableData: [],
      openedAccountsDisplayData: [],
      currAccountCurrencyFilter: '',
      depositTableData: [],
      regularDepositTableData: [],
      depositDisplayData: [],
      withdrawTableData: [],
      regularWithdrawTableData: [],
      withdrawDisplayData: [],
      depositAccountName: '',
      depositAccountNumber: '',
      withdrawAccountName: '',
      withdrawAccountNumber: '',
      timeTypeList: [
        // BE field name: last_update_time, FE display name: Processed Time
        { label: this.$t('common.keys.lastUpdateTime'), value: 2 },
        // BE field name: create_time, FE display name: Submission Time
        { label: this.$t('common.keys.createTime'), value: 1 }
      ],
      timeType: 2,
    };
  },
  methods: {
    closeDownloadDoalog() {
      this.updateTimeVisible = true;
      this.downloadVisible = false;
    },
    onConfrim(startDate, endDate, isUpdate) {
      if (isUpdate) this.updateTimeVisible = false;
      this.startDate = startDate;
      this.endDate = endDate;
      this.queryIbReportData().then(async res => {
        const downloadFile = await this.downLoadExcel();
        if (downloadFile) {
          this.$message({
            message: this.$t('ibReport.successInfo'),
            type: 'success'
          });
        } else {
          this.$message({
            message: '',
            type: 'error'
          });
        }
        if (isUpdate) this.closeDownloadDoalog();
      });
    },
    async downLoad() {
      // 间隔天数
      let days = this.$options.filters.formatDateDiff(this.startDate, this.endDate);
      // 允许下载最大天数
      const MAX_DAYS = 90;
      if (days > MAX_DAYS) {
        //大于90天弹框 重新选择日期再下载
        this.downloadVisible = true;
        return Promise.resolve();
      }
      //在90天范围内直接下载excel
      this.onConfrim(this.startDate, this.endDate);
    },
    async downLoadExcel() {
      // 表名称
      const fileName = `${this.accountID}-${this.$t('ibReport.header')}${this.timeFrame}`;
      // sheet 表头
      // 入金
      const ibaccountHeader = [
        this.downloadIbHeader,
        'home.Account',
        'home.Name',
        'common.keys.method',
        'common.keys.currency',
        'ibReport.depositAmount'
      ].map(key => this.$t(key));
      //出金
      const withdrawIbaccountHeader = [
        this.downloadIbHeader,
        'home.Account',
        'home.Name',
        'common.keys.method',
        'common.keys.currency',
        'ibReport.withdrawalAmount'
      ].map(key => this.$t(key));
      //开户
      const openedAccountHeader = [
        'common.keys.DATE',
        'common.keys.ACCNUM',
        'common.keys.NAME',
        'common.keys.EMAIL',
        'common.keys.ACCTYPE',
        'common.keys.PLATFORM',
        'common.keys.BASECURRENCY',
        'common.keys.BALANCE'
      ].map(key => this.$t(key));
      // sheet名称
      const depositName = `${this.$t('ibReport.deposits')}`;
      const withdrawName = `${this.$t('ibReport.withdrawals')}`;
      const openedAccsName = `${this.$t('ibReport.openedAccs')}`;
      const depositData = {
        sheetData: this.parseExcelData(this.regularDepositTableData, 'deposits'),
        sheetName: depositName.slice(0, 31),
        sheetHeader: ibaccountHeader,
        columnWidths: [5, 7, 6, 8, 7, 8] //宽度
      };
      const withdrawData = {
        sheetData: this.parseExcelData(this.regularWithdrawTableData, 'withdraw'),
        sheetName: withdrawName.slice(0, 31),
        sheetHeader: withdrawIbaccountHeader,
        columnWidths: [5, 7, 6, 8, 7, 8]
      };
      const openedAccountsData = {
        sheetData: this.parseOpenedAccountsExcelData(this.openedAccountsTableData),
        sheetName: openedAccsName.slice(0, 31),
        sheetHeader: openedAccountHeader,
        columnWidths: [5, 7, 6, 8, 7, 8]
      };
      let datas = [];
      switch (this.selectedCard) {
        case 'netFunding':
          datas = [depositData, withdrawData];
          break;
        case 'deposits':
          datas = [depositData];
          break;
        case 'withdraw':
          datas = [withdrawData];
          break;
        case 'openedAccounts':
          datas = [openedAccountsData];
          break;
        default:
          break;
      }
      if (datas.length) return await downLoadExcelMode(fileName, datas);
    },
    parseExcelData(traderTableData, type) {
      return traderTableData.map(item => {
        const {
          createTime, // this.timeType: 1
          updateTime, // this.timeType: 2
          mt4Account,
          realName,
          currency,
          amount,
          paymentType,
          paymentChannel,
          withdrawType,
          transferCountry
        } = item;
        return {
          createTime: createTime
            ? this.$options.filters.formatDate(createTime)
            : this.$options.filters.formatDate(updateTime),
          mt4Account,
          realName,
          method:
            type === 'deposits'
              ? this.showDepositMethod(paymentType, paymentChannel)
              : this.showWithdrawMethod(withdrawType, transferCountry),
          currency,
          amount
        };
      });
    },
    parseOpenedAccountsExcelData(traderTableData) {
      return traderTableData.map(item => {
        const { approvedDate, mt4Account, name, email, platform, currency, balance, type } = item;
        return {
          approvedDate: this.$options.filters.date(approvedDate),
          mt4Account,
          name,
          email,
          type: this.$config.accountTypeMaps[type],
          platform,
          currency,
          balance: this.$options.filters.currencySymbol(currency) + balance
        };
      });
    },
    handleTimeTypeChange() {
      this.queryIbReportData();
    },
    queryIbReportData() {
      if (this.accountID === null || this.accountID.length < 1) {
        return Promise.resolve();
      }

      return apiQueryIbReportData({
        userId: this.userID,
        rebateAccount: this.accountID,
        startDate: this.startDate,
        endDate: this.endDate,
        timeType: this.timeType
      }).then(resp => {
        this.depositAccountName = '';
        this.depositAccountNumber = '';
        this.withdrawAccountName = '';
        this.withdrawAccountNumber = '';
        this.$refs.depositTable.clearSort();
        this.$refs.withdrawTable.clearSort();

        const respData = resp.data;
        this.cardData.netFunding.value = respData.netFunding;
        this.cardData.deposits.value = respData.totalDeposit;
        this.cardData.withdraw.value = respData.totalWithdraw;
        this.cardData.openedAccounts.value = respData.openedAccounts;

        this.fundingTableData = respData.funding;
        this.openedAccountsTableData = respData.openedAccountsDetail;
        this.depositTableData = respData.deposits;
        this.regularDepositTableData = respData.deposits;
        this.withdrawTableData = respData.withdraws;
        this.regularWithdrawTableData = respData.withdraws;

        this.currAccountCurrencyFilter = this.$options.filters.currencySymbol(this.currAccountCurrency);
      }, this.$handleError);
    },
    sortData() {
      this.sorting.column = this.sorting.column || 'balance';
      this.sorting.order = this.sorting.order || 'descending';
      this.sorting.tableName = this.sorting.tableName || 'openedAccountTable';
      if (this.sorting.tableName === 'depositTable') {
        this.depositTableData.sort((a1, a2) => {
          return this.sorting.order == 'descending'
            ? a2[this.sorting.column] - a1[this.sorting.column]
            : a1[this.sorting.column] - a2[this.sorting.column];
        });
      } else if (this.sorting.tableName === 'withdrawTable') {
        this.withdrawTableData.sort((a1, a2) => {
          return this.sorting.order == 'descending'
            ? a2[this.sorting.column] - a1[this.sorting.column]
            : a1[this.sorting.column] - a2[this.sorting.column];
        });
      } else {
        this.openedAccountsTableData.sort((a1, a2) => {
          return this.sorting.order == 'descending'
            ? a2[this.sorting.column] - a1[this.sorting.column]
            : a1[this.sorting.column] - a2[this.sorting.column];
        });
      }
    },
    sortChange(column, prop, order) {
      if (!column.column) {
        return;
      }
      this.sorting = {
        column: column.column.property,
        order: column.order,
        tableName: column.column.className
      };
      this.sortData();
    },
    searchDepositData() {
      this.depositTableData = this.regularDepositTableData.filter(
        deposit =>
          (!this.depositAccountNumber ||
            deposit.mt4Account
              .toString()
              .toLowerCase()
              .includes(this.depositAccountNumber.toLowerCase())) &&
          (!this.depositAccountName ||
            deposit.realName
              .toString()
              .toLowerCase()
              .includes(this.depositAccountName.toLowerCase()))
      );
    },
    searchWithdrawData() {
      this.withdrawTableData = this.regularWithdrawTableData.filter(
        withdraw =>
          (!this.withdrawAccountNumber ||
            withdraw.mt4Account
              .toString()
              .toLowerCase()
              .includes(this.withdrawAccountNumber.toLowerCase())) &&
          (!this.withdrawAccountName ||
            withdraw.realName
              .toString()
              .toLowerCase()
              .includes(this.withdrawAccountName.toLowerCase()))
      );
    },
    showDepositMethod(method, channel) {
      if (!depositHistory[method]) return method;
      const depositMethods = depositHistory[method][channel]
        ? depositHistory[method][channel].value
        : depositHistory[method].default.value;
      return this.$options.filters.methods(depositMethods);
    },
    showWithdrawMethod(method, channel) {
      if (!withdrawHistory[method]) return method;
      const withdrawMethod = withdrawHistory[method][channel]
        ? withdrawHistory[method][channel].value
        : withdrawHistory[method].default.value;
      return this.$options.filters.methods(withdrawMethod);
    },
  },
  watch: {
    '$route.query': {
      handler(query) {
        this.selectedCard = query.cardMethod || 'netFunding';
      },
      immediate: true
    }
  },
  computed: {
    timeFrame() {
      return `(${this.$options.filters.formatDate(this.startDate, 'DD-MM-YYYY')}~${this.$options.filters.formatDate(
        this.endDate,
        'DD-MM-YYYY'
      )})`;
    },
    accountDataPermission() {
      return this.$store.state.common.permissionIds.includes(1) || !this.$store.state.common.isSubUser;
    },
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    timeTypeDefault() {
      return this.timeType === 1 ? 'createTime' : 'updateTime';
    },
    downloadIbHeader() {
      return this.timeType === 1 ? this.$t('common.keys.createTime') : this.$t('common.keys.lastUpdateTime');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/ibReport.scss';
</style>
<style></style>
